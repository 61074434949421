import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    fromEvent,
    merge,
    Observable,
    of,
    shareReplay,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { environment } from '@environments/environment';
import { EntData } from '@bazis/shared/models/srv.types';
import { isLocal } from '@bazis/utils';

interface SocketConnection {
    message$: Observable<any>;
    opened$: Observable<any>;
    error$: Observable<any>;
    closed$: Observable<any>;
}

const WS_URL = environment.wsUrl;

@Injectable({ providedIn: 'root' })
export class BazisWebSocketService {
    private socket;

    private retry$ = new BehaviorSubject(true);

    socketData$: Observable<SocketConnection> = combineLatest([
        this.retry$,
        this.authService.token.$,
    ]).pipe(
        debounceTime(0),
        map(([retry, token]) => {
            this.close();
            if (token) {
                const socket: SocketConnection = this.create();
                return { ...socket };
            }
            return {
                message$: of(''),
                opened$: of(null),
                error$: of(null),
                closed$: of(null),
            };
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    socketConnection$ = this.socketData$.pipe(
        switchMap((data) => merge(data.closed$, data.opened$, data.error$)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    message$ = this.socketData$.pipe(
        switchMap((v) => v.message$),
        filter((message) => !!message),
        map((message: any): EntData => {
            const messageData = JSON.parse(message.data);
            const data = JSON.parse(messageData.data);
            return {
                id: data.id,
                type: 'notification.notice',
                $snapshot: {
                    ...data,
                },
            };
        }),
        tap((data) => {
            console.log('[SOCKET MESSAGE]', data);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(private authService: BazisAuthService) {
        this.socketConnection$.subscribe();
    }

    close() {
        if (this.socket) {
            console.log('front closes connection');
            this.socket.close();
            this.socket = null;
        }
    }

    create(): SocketConnection {
        let url = WS_URL;
        if (!isLocal()) {
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            url = `${protocol}://${window.location.host}/ws`;
        }

        this.socket = new WebSocket(url);

        const opened$ = fromEvent(this.socket, 'open').pipe(
            withLatestFrom(this.authService.token.$),
            tap(([connect, token]) => {
                console.log('Socket connected');
                this.sendMessage(JSON.stringify({ token }));
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        const error$ = fromEvent(this.socket, 'error').pipe(
            tap((error) => {
                console.log('Socket connection error', error);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        const closed$ = fromEvent(this.socket, 'close').pipe(
            tap(() => {
                console.log('Socket closed');
                if (this.socket) {
                    this.socket = null;
                }
            }),
            debounceTime(5000),
            tap(() => {
                this.retry$.next(true);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        const message$ = fromEvent(this.socket, 'message').pipe(shareReplay(SHARE_REPLAY_SETTINGS));

        return { opened$, error$, closed$, message$ };
    }

    sendMessage(message: string) {
        this.socket.send(message);
    }
}
