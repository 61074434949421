import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { EntData, EntDocumentSettings } from '@bazis/shared/models/srv.types';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { from, Observable, of, shareReplay, Subject, timer, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root',
})
export class BazisDocusignService {
    integrationKey = '';

    docuSignStyle = {
        /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
        /** does not support CSS variables **/
        branding: {
            primaryButton: {
                /** Background color of primary button */
                backgroundColor: '#333',
                /** Text color of primary button */
                color: '#fff',
            },
        },

        /** High-level components we allow specific overrides for */
        signingNavigationButton: {
            finishText: 'You have finished the document!',
            position: 'bottom-center',
        },
    };

    docusign$ = from((<any>window).DocuSign.loadDocuSign(this.integrationKey)).pipe(shareReplay());

    constructor(
        protected authService: BazisAuthService,
        protected srvService: BazisSrvService,
        protected translocoService: TranslocoService,
    ) {}

    generateUrl(item: EntDocumentSettings) {
        return (
            this.srvService.generateBasicUrl(
                item.entityType,
                item.entityId,
                `docu_sign/${item.contextLabel}`,
            ) +
            '?bazis_auth=' +
            this.authService.token._
        );
    }

    getDocuSignUrl$(entityType, entityId, contextLabel) {
        const url = `${entityType.split('.').join('/')}/${entityId}/docu_sign/${contextLabel}`;
        return this.srvService.commonGetRequest$(url).pipe(map((r) => r.url));
    }

    signDocument$(
        item: EntDocumentSettings,
        documentEntity,
        url: string = null,
        elementId: string = null,
    ) {
        const url$: Observable<string> = url
            ? of(url)
            : this.getDocuSignUrl$(item.entityType, item.entityId, item.contextLabel);

        return url$.pipe(
            switchMap((docusignUrl) => this.sign$(item, docusignUrl, elementId)),
            switchMap((r) => r.sessionEnd$),
            map(() => {
                return {
                    checkSignature$: this.checkSignatureForItem$(item, documentEntity).pipe(
                        shareReplay(SHARE_REPLAY_SETTINGS),
                    ),
                };
            }),
        );
    }

    sign$(item: EntDocumentSettings, url = null, elementId = null) {
        if (!elementId) elementId = `docusign-${item.entityId}`;

        return this.docusign$.pipe(
            map((docusign: any) => {
                const signing = docusign.signing({
                    url,
                    displayFormat: 'default',
                    style: this.docuSignStyle,
                });

                signing.mount('#' + elementId);

                const sessionEnd = new Subject();
                signing.on('sessionEnd', (e) => {
                    if (e.sessionEndType === 'signing_complete') {
                        sessionEnd.next(e);
                    }
                });

                return {
                    sessionEnd$: sessionEnd.asObservable(),
                };
            }),
            catchError((e) => {
                return of(null);
            }),
        );
    }

    checkSignatureForItem$(item, documentEntity): Observable<EntData> {
        return timer(5000).pipe(
            switchMap(() => this.srvService.fetchEntity$(item.entityType, item.entityId)),
            withLatestFrom(this.authService.userId$),
            switchMap(([entity, userId]) => {
                const target = entity.$snapshot.sign_documents.find(
                    (v) =>
                        v.id === documentEntity.id &&
                        documentEntity.type === v.type &&
                        v.attributes.is_active,
                );

                const signed = target?.attributes?.envelope_items?.find(
                    (v) => v.attributes.author.id === userId,
                );

                if (signed) return of(entity);

                return this.checkSignatureForItem$(item, documentEntity);
            }),
        );
    }
}
